import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, Container } from 'react-bootstrap';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';

const Navigation = (props) => {
  const { userInfo, isLoading } = props;
  return (
    isLoading === true ? null : 
      (
        userInfo ? (
          <NavigationAuth />
        ) : (
          <NavigationNonAuth />
        )
      )
  )
      };

const NavigationAuth = () => (
  <Nav className="justify-content-end w-100">
    <Nav.Item className="mx-3">
      <a href="https://applikation.zendesk.com/">
        <p className="my-2 px-2" style={{color:"black"}}>Contact</p>
      </a>
    </Nav.Item>
    <Nav.Item className="mx-3">
      <SignOutButton/>
    </Nav.Item>
  </Nav>
);

const NavigationNonAuth = () => (
  <Nav className="justify-content-center">
  </Nav>
);

export default Navigation;

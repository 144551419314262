import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';

import { AuthConsumer } from '../../react-check-auth';

import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

// import { useCheckAuth } from "../../react-check-auth";

import startSession from '../../common/api/startSession';

const SignInPage = () => (
  <div className="bg-primary vh-100">
    <Container>
      <Row>
        <Col className="align-content-center justify-content-center text-center">
          <img src="/logo.png" style={{ margin: '3em 0', maxHeight: 100 }} alt="logo" />
        </Col>
      </Row>
      <Row>
        <Col className="align-content-center justify-content-center text-center"><h2>Login</h2></Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <SignInForm />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }} >
          {/* <SignInGoogle /> */}
          {/* <SignInFacebook /> */}
          {/* <SignInTwitter /> */}
          { <PasswordForgetLink /> }
          { <SignUpLink/> }
        </Col>
      </Row>
    </Container>
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;

const SignInFormBase = ({ firebase,history }) => {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(INITIAL_STATE);

  const onSubmit = (event, refreshAuth) => {
    firebase
      .doSignInWithEmailAndPassword(user.email, user.password)
      .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
      .then(startSession) // Send to our server and start the session
      .then(() => {
        setUser(INITIAL_STATE);
        refreshAuth();
        history.push(ROUTES.ACCOUNT);
      })
      .catch(error => {
        setError(error);
      });

    event.preventDefault();
  };

  const onChange = event => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  return (
    <AuthConsumer>
      {({ refreshAuth }) => (
        <Form onSubmit={(e)=>onSubmit(e,refreshAuth)}>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              name="email"
              value={user.email}
              onChange={onChange}
              type="text"
              placeholder="Email Address" />
          </Form.Group>
          <Form.Group controlId="formPassword1">
            <Form.Label>Password</Form.Label>
            <Form.Control
              name="password"
              value={user.password}
              onChange={onChange}
              autoComplete="current-password"
              type="password"
              placeholder="Password" />
          </Form.Group>
          <Button variant="secondary" disabled={user.password === '' || user.email === ''} type="submit" className="mt-3 btn-focal btn-lg">
            Login
          </Button>
          {error && <Alert variant="danger" className="my-4">Unable to sign you in.</Alert>}
        </Form>
      )}
    </AuthConsumer>
  );
};

class SignInGoogleBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event, refreshAuth) => {
    this.props.firebase
      .doSignInWithGoogle()
      .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
      .then(startSession) // Send to our server and start the session
      .then(() => {
        this.setState({ error: null });
        refreshAuth();
        this.props.history.push(ROUTES.ACCOUNT);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <AuthConsumer>
      {({ refreshAuth }) => (
        <Form onSubmit={(e)=>this.onSubmit(e,refreshAuth)}>
          <Button variant="secondary" type="submit" className="mt-3 btn-focal btn-lg">
            Sign In With Google
          </Button>
          {error && <Alert variant="danger">Unable to sign you in.</Alert>}
        </Form>
      )}
      </AuthConsumer>
    );
  }
}

class SignInFacebookBase extends Component {
  constructor(props) {
    super(props);

    this.state = { error: null };
  }

  onSubmit = (event, refreshAuth) => {
    this.props.firebase
      .doSignInWithFacebook()
      .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
      .then(startSession) // Send to our server and start the session
      .then(() => {
        this.setState({ error: null });
        refreshAuth();
        this.props.history.push(ROUTES.ACCOUNT);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }

        this.setState({ error });
      });

    event.preventDefault();
  };

  render() {
    const { error } = this.state;

    return (
      <AuthConsumer>
      {({ refreshAuth }) => (
        <Form onSubmit={(e)=>this.onSubmit(e,refreshAuth)}>
          <Button variant="secondary" type="submit" className="mt-3 btn-focal btn-lg fbButton">
            Login With Facebook
          </Button>
          {error && <Alert variant="danger" className="my-4">{error.message}</Alert>}
        </Form>
      )}
      </AuthConsumer>
    );
  }
}

// class SignInTwitterBase extends Component {
//   constructor(props) {
//     super(props);

//     this.state = { error: null };
//   }

//   onSubmit = event => {
//     this.props.firebase
//       .doSignInWithTwitter()
//       .then(authResult => authResult.user.getIdToken()) // Get user ID token from Firebase
//       .then(startSession) // Send to our server and start the session
//       .then(() => {
//         this.setState({ error: null });
//         refreshAuth();
//         this.props.history.push(ROUTES.ACCOUNT);
//       })
//       .catch(error => {
//         if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
//           error.message = ERROR_MSG_ACCOUNT_EXISTS;
//         }

//         this.setState({ error });
//       });

//     event.preventDefault();
//   };

//   render() {
//     const { error } = this.state;

//     return (
//       <Form onSubmit={this.onSubmit}>
    //     <Button variant="secondary" type="submit" className="mt-3 btn-focal btn-lg">
    //     Login With Twitter
    //   </Button>
    //   {error && <Alert variant="danger">{error.message}</Alert>}
    // </Form>
//     );
//   }
// }

const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
  withRouter,
  withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
  withRouter,
  withFirebase,
)(SignInFacebookBase);

// const SignInTwitter = compose(
//   withRouter,
//   withFirebase,
// )(SignInTwitterBase);

export default SignInPage;

export {
  SignInForm,
  SignInGoogle,
  SignInFacebook /* , SignInTwitter */,
};

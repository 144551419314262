import React from 'react';
import DropIn from "braintree-web-drop-in-react";

import useFetchClientToken from '../../common/utils/useFetchClientToken';
import { Row, Container, Button, Alert } from 'react-bootstrap';
import { withRouter, useParams, Redirect } from 'react-router-dom';

import Loading from '../Loading';
import * as ROUTES from '../../constants/routes';

import api from '../../api'
 
class CancelPage extends React.Component {

  instance;
 
  state = {
    loading: false,
    error: false,
    success: false,
    liveSubscription: null
  };
 
  async componentDidMount() {

    this.setState({
      loading: true
    });

    const user = this.props.userInfo;
    console.log('SUBSCRIPTIONS: ' + user.subscriptions);
    var liveSubscription;
    if (user.subscriptions) {
      if (user.subscriptions.length > 0) {
        for (var i = 0; i < user.subscriptions.length; i++) {
          if (!user.subscriptions[i].expiresAt) {
            liveSubscription = user.subscriptions[i];
            break;
          } else {
            var unixExpiryTime = Date.parse(user.subscriptions[i].expiresAt);
            var now = new Date();
            if (unixExpiryTime >= now.getTime()) {
              liveSubscription = user.subscriptions[i];
              break;
            }
          }
        }
      }
    } 

    console.log("Live Subscription: " + liveSubscription);
 
    this.setState({
      liveSubscription: liveSubscription,
      loading: false
    });
  }
 
  async cancel() {

    if (!this.state.loading) {
      this.setState({
      loading: true,
      error: false,
      success: false
      });

    // api.delete(`/users/${this.props.userInfo.id}/subscriptions/${this.state.liveSubscription.id}`)
    // .then(response => {
    //   this.setState({
    //         loading: false,
    //         success: true
    //     });
    // })
    // .catch(err => {
    //     this.setState({
    //         loading: false,
    //         error: true
    //     });
    // });
    }
  }
 
  render() {
    if (this.state.loading) {
      return (
        <div>
          <Loading/>
        </div>
      );
    }

    if (this.state.success) {
        return <Redirect to={ROUTES.ACCOUNT}/>
    }
      
      return (
        <Container>
        <div className="text-center">
        <h1 className="py-5" style={{color: "#00BFA5", fontWeight: "bold"}}>Are you sure you want to cancel your subscription?</h1>
        <p>If you click cancel your subscription will be cancelled and you will no longer have access to premium offers.</p>
          <Button variant="danger" onClick={this.cancel.bind(this)} className="my-3 btn-rounded w-50 btn-lg">Cancel</Button>
        </div>
        <div>
        { this.state.loading ? (<Loading/>) : (<div></div>)}
        { this.state.error ? (<Alert variant="danger">
          <Alert.Heading>There was a problem cancelling your subscription!</Alert.Heading>
          <p>We were unable to cancel your subscription. Please try again.</p>
        </Alert>) : (<div></div>)}
        </div>
        </Container>
        
      );
  }
}

export default CancelPage;


import React from 'react';

import { withFirebase } from '../Firebase';

import { AuthConsumer } from '../../react-check-auth';

import { Button } from 'react-bootstrap';

const SignOutButton = ({ firebase }) => (
  <AuthConsumer>
    {({ refreshAuth }) => (
      <Button style={{background:"white", color: "black", border: "none", fontWeight: "bold"}} type="button" className="btn h-100" onClick={() => {
        firebase.doSignOut() // This is a promise that calls a logout API
          .then(
            () => refreshAuth()
          );
      }}>
        Logout
      </Button>
    )}
    </AuthConsumer>
);

export default withFirebase(SignOutButton);

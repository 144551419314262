import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import * as ROUTES from '../../constants/routes';
import SignUp from './Signup';

const SignUpPage = () => (
  <div className="bg-primary vh-100">
    <Container>
      <Row>
        <Col className="align-content-center justify-content-center text-center">
          <img
            src="/logo.png"
            className="p-3 pr-5"
            style={{ margin: '3em 0', maxHeight: 100 }}
            alt="logo"
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
        <SignUp />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }}>
          {/* <SignInGoogle /> */}
          {/* <SignInFacebook /> */}
          {/* <SignInTwitter /> */}
          {/* <PasswordForgetLink /> */}
          {/* <SignUpLink /> */}
        </Col>
      </Row>
    </Container>
  </div>
);

const SignUpLink = () => (
  <div className="my-4 text-center">
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP} className="text-secondary">Sign Up</Link>
  </p>
  </div>
);

export default SignUpPage;
export { SignUpLink };

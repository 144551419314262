import React from 'react';
import PropTypes from 'prop-types'
import { Row, Container, Col } from 'react-bootstrap';

const styles = {
  inline:{
    display:'inline-block'
  }
}

const InlineOutput = ({title,data}) => {

  return (
    <div>
      <Row style={{paddingBottom: '10px' }}>
          <Col xs={12} sm={4} className="text-left py-xs-0 py-sm-2" >
            <p>{title}</p>
          </Col>
          <Col  xs={12} sm={4} className="text-left py-xs-0 py-sm-2">
            <p className="grey-text">{data}</p>
          </Col>
      </Row>
    </div>
  );
};
InlineOutput.propTypes = {
  title:PropTypes.string.isRequired,
  data:PropTypes.string.isRequired
}

export default InlineOutput;
import React from 'react';
import { Row, Container, Col } from 'react-bootstrap';

import Loading from '../Loading';
import UserInfo from './UserInfo';
import SubscriptionView from './SubscriptionView';
import PasswordChange from '../PasswordChange';

const AccountPage = (props) => {
	const { userInfo } = props;
  console.log('USERINFO: ' + userInfo);
	return (
	<Container>
    	<Row>
      		<Col sm={12} lg={6} >
        		{userInfo && <UserInfo user={userInfo} />}
      		</Col>
      		<Col>
        		<Container>
              <h4>Our apps</h4>
              <a href="https://itunes.apple.com/app/id1487934735">
              <img
              src="/downloadAppStore.png"
              style={{ margin: '1em 0', maxHeight: 60 }}
              alt="app store download"
              />
              </a>
            </Container>
      		</Col>
    	</Row>
  	</Container>
	);
};

export default AccountPage;

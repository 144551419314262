import React from 'react';
import DropIn from "braintree-web-drop-in-react";

import useFetchClientToken from '../../common/utils/useFetchClientToken';
import { Row, Container, Button, Alert } from 'react-bootstrap';
import { withRouter, useParams, Redirect } from 'react-router-dom';

import Loading from '../Loading';
import * as ROUTES from '../../constants/routes';

import api from '../../api'
 
class SubcribePage extends React.Component {

  instance;
 
  state = {
    clientToken: null,
    loading: false,
    error: false,
    success: false,
    hasLiveSubscription: false,
    plans: null
  };
 
  async componentDidMount() {
    // Get a client token for authorization from your server
    const response = await api.get(`/users/${this.props.userInfo.id}/clientToken`);
    const plansResponse = await api.get(`/plans`);
    const plans = plansResponse.data;
    const clientToken = response.data;
    var hasLiveSubscription = false
    const user = this.props.userInfo;
    if (user.subscriptions) {
      if (user.subscriptions.length > 0) {
        var liveSubscription;
        for (var i = 0; i < user.subscriptions.length; i++) {
          if (!user.subscriptions[i].expiresAt) {
            liveSubscription = user.subscriptions[i];
            break;
          } else {
            var unixExpiryTime = Date.parse(user.subscriptions[i].expiresAt);
            var now = new Date()
            if (unixExpiryTime >= now.getTime()) {
              liveSubscription = user.subscriptions[i];
              break;
            }
          }
        }

        if (liveSubscription) {
          hasLiveSubscription = true
        }
      }
    } 
 
    this.setState({
      clientToken: clientToken,
      hasLiveSubscription: hasLiveSubscription,
      plans: plans
    });
  }
 
  async subscribe() {
    // Send the nonce to your server
    if (!this.state.loading) {

      this.setState({
        loading: true,
        error: false,
        success: false
      });

      const { nonce } = await this.instance.requestPaymentMethod();
      // const paymentData = {paymentMethodNonce: nonce};
      
      // api.post(`/users/${this.props.userInfo.id}/paymentMethods`, paymentData)
      // .then(async response => {
        
      // const { nonce } = await this.instance.requestPaymentMethod();

      // Can pass paymentMethodToken or paymentMethodNonce - but for nonce's the method must be vaulted
      // which is automatic when using th drop-in
      const subscriptionData = {paymentMethodNonce: nonce, planId: this.state.plans[0].id};

      api.post(`/users/${this.props.userInfo.id}/subscriptions`, subscriptionData)
      .then(response => {
        this.setState({
          loading: false,
          success: true
        });
      })
      .catch(err => {
        this.setState({
            loading: false,
            error: true
        });
      })
      // .catch(err => {
      //     this.setState({
      //         loading: false,
      //         error: true
      //     });
      // });
    }
  }
 
  render() {

    if (this.state.success || this.state.hasLiveSubscription) {
        return <Redirect to={ROUTES.ACCOUNT}/>
    }

    if (!this.state.clientToken || !this.state.plans) {
      return (
        <div>
          <Loading/>
        </div>
      );
    } else {
      return (
        <Container>
        <div className="text-center">
        <h1 className="py-5" style={{color: "#00BFA5", fontWeight: "bold"}}>Go Premium. Because every pet deserves the best.</h1>
        <p>For just £{this.state.plans[0].price} a month you'll get access to exclusive offers you can’t find anywhere else.</p>
          <DropIn
            options={{ authorization: this.state.clientToken }}
            onInstance={instance => (this.instance = instance)}
          />
          <Button variant="primary" onClick={this.subscribe.bind(this)} className="my-3 btn-rounded w-50 btn-lg">Subscribe</Button>
        </div>
        <div>
        { this.state.loading ? (<Loading/>) : (<div></div>)}
        { this.state.error ? (<Alert variant="danger">
          <Alert.Heading>There was a problem!</Alert.Heading>
          <p>We were unable to take payment. Please try again.</p>
        </Alert>) : (<div></div>)}
        </div>
        </Container>
        
      );
    }
  }
}

export default SubcribePage;
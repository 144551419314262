import React from 'react';
import InlineOutput from '../../Info/InlineOutput'
import ImageOutput from '../../Info/ImageOutput'
import { Container, Row, Col } from 'react-bootstrap';

const UserInfo = ({user}) => {

  return (
    <Container>
      <Row>
        <Col className="text-left"><h4>Account overview</h4></Col>
      </Row>
      <Row>
        <Col style={{ paddingBottom:'25px' }} className="text-center">
          <InlineOutput data={user.firstName} title="First name"/>
          <InlineOutput data={user.lastName} title="Surname"/>
          <InlineOutput data={user.email} title="Email"/>
          <InlineOutput data="GB" title="Country"/>
        </Col>
      </Row>
    </Container>
  );
};

export default UserInfo;
import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

import { AuthConsumer } from '../../react-check-auth';

import Header from '../Header';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import AccountPage from '../Account';
import SubscribePage from '../Subscribe';
import CancelPage from '../Cancel';

import * as ROUTES from '../../constants/routes';

import AuthorizedRoute from '../AuthorizedRoute';
import UnauthorizedRoute from '../UnauthorizedRoute';

import LoadingPage from '../Loading';

import NotFoundPage from '../NotFound';

const headerExcludeRegex = /^(?!.*(\/signin|\/signup|\/pw-forget)).*$/

const App = () => (
  <Router>
    <div>
      <Route path={headerExcludeRegex} component={Header} />

      <Switch>
        <UnauthorizedRoute path={ROUTES.SIGN_UP} component={SignUpPage} />
        <UnauthorizedRoute path={ROUTES.SIGN_IN} component={SignInPage} />
        <UnauthorizedRoute path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <UnauthorizedRoute exact path={ROUTES.LANDING} component={LoadingPage} />
        <AuthorizedRoute path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default App;

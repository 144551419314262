import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { withFormik, Field } from 'formik/dist/index';
import * as yup from 'yup';
import * as _ from 'lodash';
import * as ROUTES from '../../../constants/routes';

const SignInLink = () => (
  <div className="my-4 text-center">
  <p>
    Already have an account? <Link to={ROUTES.SIGN_IN} className="text-secondary">Sign In</Link>
  </p>
  </div>
);

function equalTo(ref, msg) {
  return yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path,
    },
    test: function(value) {
      return value === this.resolve(ref);
    },
  });
}
yup.addMethod(yup.string, 'equalTo', equalTo);

const schema = yup.object({
  email: yup
    .string()
    .email()
    .required(),
  password: yup.string().required("Please enter your password").matches(
    /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
    "The password must contain at least 1 lowercase alphabetical character, 1 uppercase alphabetical character and must be 8 characters or longer"
  ),
  confirmPassword: yup
    .string()
    .equalTo(yup.ref('password'), 'Passwords must match')
    .required(),
  referralCode: yup.string().nullable(true),
});

const SignUpForm = ({
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  touched,
  isValid,
  errors,
}) => {
  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formFirstName">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="firstName"
            placeholder="Enter first name"
            value={_.get(values, 'firstName') || ''}
            name="firstName"
            onChange={handleChange}
            isInvalid={!!_.get(errors, 'firstName')}
          />
          <Form.Control.Feedback type="invalid">
            {_.get(errors, 'firstName')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formLastName">
          <Form.Label>Surname</Form.Label>
          <Form.Control
            type="lastName"
            placeholder="Enter surname"
            value={_.get(values, 'lastName') || ''}
            name="lastName"
            onChange={handleChange}
            isInvalid={!!_.get(errors, 'lastName')}
          />
          <Form.Control.Feedback type="invalid">
            {_.get(errors, 'lastName')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={_.get(values, 'email') || ''}
            name="email"
            onChange={handleChange}
            isInvalid={!!_.get(errors, 'email')}
          />
          <Form.Control.Feedback type="invalid">
            {_.get(errors, 'email')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={_.get(values, 'password') || ''}
            name="password"
            onChange={handleChange}
            isInvalid={!!_.get(errors, 'password')}
          />
          <Form.Control.Feedback type="invalid">
            {_.get(errors, 'password')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formConfirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Confirm password"
            value={_.get(values, 'confirmPassword') || ''}
            name="confirmPassword"
            onChange={handleChange}
            isInvalid={!!_.get(errors, 'confirmPassword')}
          />
          <Form.Control.Feedback type="invalid">
            {_.get(errors, 'confirmPassword')}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formReferralCode">
          <Form.Label>Referral code</Form.Label>
          <Form.Control
            type="text"
            placeholder="Referral code"
            value={_.get(values, 'referralCode') || ''}
            name="referralCode"
            onChange={handleChange}
            isInvalid={!!_.get(errors, 'referralCode')}
          />
          <Form.Control.Feedback type="invalid">
            {_.get(errors, 'referralCode')}
          </Form.Control.Feedback>
        </Form.Group>
        <Button
          variant="secondary"
          type="submit"
          className="mt-3 btn-focal btn-lg"
        >
          Sign up
        </Button>
        <SignInLink />
      </Form>
    </div>
  );
};

export default withFormik({
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },
  validationSchema: schema,
})(SignUpForm);
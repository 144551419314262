import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Container, Col } from 'react-bootstrap';
import Form from './Form';
import Loading from '../Loading';
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';

const PasswordChange = ({ history,firebase }) => {
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  if (error) return <div>{error.message}</div>;
  if (loading) return <Loading />;

  const onSubmit = async data => {
    setLoading(true);
    firebase
      .doPasswordUpdate(data.password)
      .then(() => {
        setLoading(false);
        history.push(ROUTES.ACCOUNT);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <>
      <h2 className="my-4 text-center">Change your password</h2>
      <Form onSubmit={onSubmit} />
    </>
  );
};

export default compose(
  withRouter,
  withFirebase,
)(PasswordChange);

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// import Loading from '../Loading';
import Navigation from '../Navigation';
import { AuthConsumer } from '../../react-check-auth';

const styles = {
  row: {
    maxHeight: 100,
  },
  image: {
    maxHeight: 100,
  },
  headerCol: {},
};

const Header = () => (
  <AuthConsumer>
    {({ userInfo, isLoading }) => {
      return (
        isLoading ? null : (
          <Container>
            <Row className="my-5">
              <Col className="align-content-start justify-content-start">
                <img src="/logo-bw.png" style={styles.image} className="p-3 pr-5" alt="logo" />
              </Col>
              <Col className="text-center my-auto">
              </Col>
              <Col className="d-flex align-items-center">
              <Navigation isLoading={isLoading} userInfo={userInfo} />
              </Col>
            </Row>
          </Container>
        )
      )
    }
    }
  </AuthConsumer>
);

export default Header;

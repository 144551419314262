import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div className="bg-primary vh-100">
    <Container>
      <Row>
        <Col className="align-content-center justify-content-center text-center">
          <img src="/logo.png" style={{ margin: '3em 0', maxHeight: 100 }} alt="logo" />
        </Col>
      </Row>
      <Row>
        <Col className="align-content-center justify-content-center text-center"><h2 className="my-4">Forgotten Password</h2></Col>
      </Row>
      <Row className="mt-3">
        <Col md={{ span: 6, offset: 3 }} >
          <PasswordForgetForm />
        </Col>
      </Row>
    </Container>
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === '';

    return (
      <Form onSubmit={this.onSubmit}>
        <Form.Label>Email</Form.Label>
        <Form.Control
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
        />
        <Button disabled={isInvalid} type="submit" className="mt-3 btn-focal btn-lg btn-secondary">
          Reset My Password
        </Button>

        {error && <p>{error.message}</p>}
      </Form>
    );
  }
}

const PasswordForgetLink = () => (
  <p className="text-center my-4">
    <Link to={ROUTES.PASSWORD_FORGET} className="text-secondary">Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
